import _ from 'lodash';
import get from 'lodash/get';
import lt from 'lodash/lt';
import gt from 'lodash/gt';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import uniqBy from 'lodash/uniqBy';
import intersection from 'lodash/intersection';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';

import { start, success, fail, apiAction } from '../middleware/fetch';
import { resetLoader } from './loading';
import { getUserId } from 'selectors/user';
import { PAYMENT_INTENT } from './payment';
import getFlags from './features';
import moment from 'moment';
import { getDevice, getParsedUA } from 'utils';

export const GET_USER_DETAILS = `subscription/GET_USER_DETAILS`;
export const SUBSCRIPTION_GET_ALL = `subscription/GET_ALL`;
export const SUBSCRIPTION_GET_OS_OFFER = `subscription/GET_OS_OFFER`;
export const CUSTOMER_SUBSCRIPTION_GET_ALL = `subscription/CUSTOMER_GET_ALL`;
export const CUSTOMER_SUBSCRIPTION_UPDATE = `subscription/CUSTOMER_UPDATE`;
export const SUBSCRIPTION_SELECTION_UPDATED = `subscription/SELECTION_UPDATED`;
export const CUSTOMER_SUBSCRIPTION_GET_RECURRING = `subscription/GET_RECURRING`;

// Dollar
const DEFAULT_CURRENCY = '$';
// 10% tax
const DEFAULT_TAX = 0.1;

// Grace period on/off
//const CLEENG_SUBSCRIPTION_GRACE_PERIOD_ENABLED = false;
// 20 minutes in ms (normal expected delay across all subscriptions/passes)
const CLEENG_ENTITLEMENT_DELAY = 1200 * 1000;
// 25 hours in ms (24hr grace period + an hour delay due to cc script runs every hr)
// const CLEENG_SUBSCRIPTION_GRACE_PERIOD = CLEENG_SUBSCRIPTION_GRACE_PERIOD_ENABLED
//   ? 90000 * 1000
//   : 0;
// 24 hours and 20 minutes in ms
// const CLEENG_TOTAL_GRACE_PERIOD = CLEENG_SUBSCRIPTION_GRACE_PERIOD_ENABLED
//   ? CLEENG_ENTITLEMENT_DELAY + CLEENG_SUBSCRIPTION_GRACE_PERIOD
//   : 0;

const initialState = {
  offers: null,
  osOffers: [],
  osOffersExtra: [],
  error: null,
  cancel: null,
  selections: [],
};

const periodTextMapping = {
  '1d': { id: `daily-pass`, short: `day`, long: `Daily`, altLong: `daily` },
  '7d': { id: `weekly-pass`, short: `week`, long: `Weekly`, altLong: `weekly` },
  '1m': {
    id: `monthly-pass`,
    short: `month`,
    long: `Monthly`,
    altLong: `monthly`,
  },
  '3months': {
    id: `3-month-pass`,
    short: `3 month`,
    long: `3 Month`,
    altLong: `every 3 months`,
  },
  '3m': {
    id: `3-month-pass-3m`,
    short: `3 month`,
    long: `3 Month`,
    altLong: `every 3 months`,
  },
  '6m': {
    id: `6-month-pass`,
    short: `6 month`,
    long: `6 Month`,
    altLong: `every 6 months`,
  },
  '12m': {
    id: `annual-pass`,
    short: `year`,
    long: `Annual`,
    altLong: `annually`,
  },
};

export const offerStates = {
  disabled: 'disable',
};

export const subscriptionStates = {
  none: `none`,
  pass: `not_eligible`, // subscriptions with type: 'pass'
  expired: `expired`,
  active: `active`,
  cancelled: `stopped`,
  invalid: `invalid`,
};

export const offerTypes = {
  pass: 'pass',
  subscription: 'subscription',
};

// List of d2c subscription types
export const d2cSubscriptionTypes = {
  ios: `paymentSource_appstore`,
  android: `paymentSource_playstore`,
  creditcard: `paymentSource_cc`,
};

// Sorted by priority from least to top priority
export const subscriptionTypes = {
  none: `none`,
  // Priority Group #4
  prepaid: `paymentSource_prepaid`,
  voucher: `paymentSource_voucher`,
  csm: `paymentSource_csm`,
  saturn: `paymentSource_saturn_migration_pass`,
  // Priority Group #3
  ios: `paymentSource_appstore`,
  android: `paymentSource_playstore`,
  // Priority Group #2
  creditcard: `paymentSource_cc`,
  // Priority Group #1
  fetchtv: `paymentSource_fetchtv`,
  legacy: `paymentSource_legacy`,
  postpaid: `paymentSource_postpaid`,
  subhub: `paymentSource_hyperion`,
  subhubv2: `paymentSource_optus_subhub`,
  internationalPass: `international_pass`,
};

export function getPeriodShortName(period) {
  const { short } = periodTextMapping[period] || {};
  return short || period;
}

export function getPeriodLongName(period) {
  const { long } = periodTextMapping[period] || {};
  return long || period;
}
export function getPeriodAltLongName(period) {
  const { altLong } = periodTextMapping[period] || {};
  return altLong || period;
}

export const isYearly = (p) => getPeriodShortName(p) === `year`;

export const isMonthly = (p) => getPeriodShortName(p) === `month`;

export function calculateSavings(offers) {
  const [monthly] = offers.filter(({ period }) => isMonthly(period));
  const [yearly] = offers.filter(({ period }) => isYearly(period));
  return monthly && yearly ? monthly.price * 12 - yearly.price : null;
}

export function getOfferTitle(offer) {
  const { offerCode, title } = offer;
  return title || offerCode;
}

export function isOfferEnabled(offer) {
  if (isEmpty(offer)) {
    return false;
  }
  const { tags } = offer;
  return !tags?.includes(offerStates.disabled);
}

/**
 * createDisabledOffer
 * This function is for use for active subscriptions whose offers has been disabled.
 * The disabled offer will not be returned from the GetOffers API. So this function
 * will return a disabled offer to stand in for the missing offer.
 *
 * @export
 * @param {*} offer The offer
 * @returns The disabled offer
 */
export function createDisabledOffer(offer) {
  if (isEmpty(offer)) {
    return null;
  }
  return {
    ...offer,
    tags: [...offer.tags, offerStates.disabled],
  };
}

/**
 * sortOrderComparator
 * The sort comparator that makes use of the index
 * of the sortOrder array to determine sorting.
 *
 * @param {*} sortOrder
 * @param {boolean} [desc=true]
 * @returns
 */
function sortOrderComparator(sortOrder, desc = true) {
  const indexOf = (item) => sortOrder.indexOf(item);
  return (item1, item2, context1, context2) => {
    const comparator = desc ? lt : gt;

    if (indexOf(item1) === indexOf(item2)) {
      // We need to sort further if items are credit card subscriptions
      if (context1 && context2) {
        if (item1 === item2 && item1 === subscriptionTypes.creditcard) {
          const expiresAt1 = get(context1, 'expiresAt', 0);
          const expiresAt2 = get(context2, 'expiresAt', 0);

          return comparator(expiresAt1, expiresAt2) ? 1 : -1;
        }
      }

      return 0;
    }

    if (item1 == null) {
      return desc ? 1 : -1;
    }

    if (item2 == null) {
      return desc ? -1 : 1;
    }

    return comparator(indexOf(item1), indexOf(item2)) ? 1 : -1;
  };
}

function sortOffers(offers, desc = true) {
  const sortOrder = Object.keys(periodTextMapping);
  const comparator = sortOrderComparator(sortOrder, desc);
  return offers?.sort((o1, o2) => {
    const sortedByPeriod = comparator(o1?.period, o2?.period);
    if (sortedByPeriod === 0) {
      if (o1?.price > o2?.price) {
        return !desc ? 1 : -1;
      }
      if (o1?.price < o2?.price) {
        return !desc ? -1 : 1;
      }
      return 0;
    }
    return sortedByPeriod;
  });
}

function sortSubscriptionsByExpiresAt(subscriptions, desc = true) {
  const comparator = desc ? lt : gt;
  return subscriptions.sort((s1, s2) => {
    const expiresAt1 = get(s1, 'expiresAt', 0);
    const expiresAt2 = get(s2, 'expiresAt', 0);

    return comparator(expiresAt1, expiresAt2) ? 1 : -1;
  });
}

export const getPurchaseSubscriptionViaStoreUrl = () => {
  const parsedUA = getParsedUA();
  const osName = _.get(parsedUA, ['os', 'name'], '');
  const userDevice = getDevice();
  const deviceType = _.get(userDevice, 'type', 'PC');
  if (deviceType === 'PC') {
    if (['ios', 'mac os'].includes(osName.toLowerCase())) {
      return 'https://itunes.apple.com/au/app/optus-sport/id1113368382?mt=8';
    } else {
      return 'http://play.google.com/store/apps/details?id=au.com.optus.sport';
    }
  } else {
    return 'https://c5pq6.app.goo.gl/?link=https://sport.optus.com.au&apn=au.com.optus.sport&isi=1113368382&ibi=au.com.optus.epl&ius=optussport';
  }
};

// TODO: Move to utils
function getErrorCode(error = {}) {
  const { errors = [] } = error;
  const [firstError = {}] = errors;
  const { code } = firstError;
  return code;
}

// TODO: Move to utils
function isNonCustomer(code) {
  return code === 10;
}

// TODO: Remove this after testing out combinations...
function dummyInsertSuffix(subscriptions) {
  // const insertDummySuffix = (tags) => tags.map(tag => `${tag}-dummy-suffix`);
  return subscriptions.map((subscription) => ({
    ...subscription,
    // accessToTags: insertDummySuffix(subscription.accessToTags),
  }));
}

// TODO: Remove this after testing out combinations...
function dummySubscriptions(subscriptions) {
  return [
    ...subscriptions,
    // {
    //   offerId: `${subscriptionTypes.saturn}1`,
    //   status: subscriptionStates.active,
    //   expiresAt: 8640000000000000/1000,
    //   accessToTags: [
    //     subscriptionTypes.saturn,
    //     // subscriptionTypes.subhubv2,
    //     // subscriptionTypes.csm,
    //     // subscriptionTypes.creditcard,
    //     // subscriptionTypes.voucher,
    //     // subscriptionTypes.android,
    //     // subscriptionTypes.premium,
    //   ],
    //   snapshotOffer: {
    //     tags: [
    //       subscriptionTypes.saturn,
    //       // subscriptionTypes.subhubv2,
    //       // subscriptionTypes.csm,
    //       // subscriptionTypes.creditcard,
    //       // subscriptionTypes.voucher,
    //       // subscriptionTypes.android,
    //       // subscriptionTypes.premium,
    //     ],
    //   },
    //   title: `Dummy Subscription - ${subscriptionTypes.saturn}`,
    // }
  ];
}

// TODO: Remove this after testing out combinations...
function dummyTransformTags(subscriptions) {
  return subscriptions.map((subscription) => {
    return {
      ...subscription,
      // accessToTags: [
      //   ...subscription.accessToTags,
      //   subscriptionTypes.postpaid,
      // ],
    };
  });
}

// TODO: Remove this after testing out combinations...
function dummyTransformStatus(subscriptions) {
  return subscriptions.map((subscription) => ({
    ...subscription,
    // status: subscriptionStates.active,
  }));
}

// TODO: Remove this after testing out combinations...
function dummyTransforms(subscriptions) {
  // TODO: Remove this after testing out combinations...
  subscriptions = dummySubscriptions(subscriptions);
  // TODO: Remove this after testing out combinations...
  subscriptions = dummyInsertSuffix(subscriptions);
  // TODO: Remove this after testing out combinations...
  subscriptions = dummyTransformTags(subscriptions);
  // TODO: Remove this after testing out combinations...
  subscriptions = dummyTransformStatus(subscriptions);
  return subscriptions;
}

const offerData = (subscription) => {
  const { snapshotOffer, accessGroups = [] } = subscription;
  const { tags, title, type, price, period } = snapshotOffer || {};
  const [fallbackTitle] = accessGroups;
  const finalTitle = title || fallbackTitle || 'Premium';
  const rawTitle = ['optus subhub', 'subhub', 'hyperion'];

  return {
    accessToTags: tags,
    title: _.includes(rawTitle, _.toLower(finalTitle))
      ? finalTitle
      : capitalize(finalTitle),
    type,
    price,
    period,
    snapshotOffer,
  };
};

function transformSubscription(subscription) {
  const {
    id: offerId,
    autorenewStatus = '',
    originalEndDate = '',
    gracePeriodSeconds = 0,
    endDate,
  } = subscription;

  const expiresAt = Date.parse(endDate);
  const originalExpiresAt = originalEndDate ? Date.parse(originalEndDate) : 0;
  const gracePeriodMs = gracePeriodSeconds * 1000;

  // We should not return subscriptions that have passed its (expiresAt + CLEENG_ENTITLEMENT_DELAY) and
  // the id contains 'terminated'
  // Context 1: When Change Plan is successfully actioned, the from/old subscrption still persists
  // Context 2: For pass subscriptions that get cancelled i.e. id: "P870284121_AU-terminated-1595398065".
  // const expiresAt = Date.parse(endDate);
  const status = autorenewStatus.toLowerCase();

  if (
    expiresAt - CLEENG_ENTITLEMENT_DELAY < Date.now() &&
    _.includes(offerId.toLowerCase(), 'terminated')
  ) {
    return null;
  }

  return {
    offerId,
    status,
    expiresAt,
    originalExpiresAt,
    gracePeriodMs,
    ...offerData(subscription),
  };
}

/**
 * transformSubscriptionFromPayload
 * Transforms the payload from API to Redux subscription shape
 *
 * @param {*} payload
 * @returns
 */
function transformSubscriptionFromPayload(payload) {
  const subscriptions = get(payload, `data.item.subscriptions`);
  let transformed = _.compact(subscriptions.map(transformSubscription));

  // TODO: Remove this after testing out combinations...
  transformed = dummyTransforms(transformed);

  // Test D2C duplication
  /*
  const dupeSubscriptionCC = {
    accessToTags: [
      "cc-offer",
      "accessGroup_premium",
      "paymentSource_cc"
    ],
    expiresAt: 1602335304000,
    offerId: "S792796051_AU",
    period: "12m",
    price: 139,
    snapshotOffer: {
      accessGroups: [
        "Premium"
      ],
      active: true,
      id: "S792796051_AU",
      offerCode: "Dummy 1 D2C - CC - Annual subscription (recurring) to Optus Sport",
      period: "12m",
      price: 139,
      segmentTag: null,
      source: "cleeng",
      tags: [
        "cc-offer",
        "accessGroup_premium",
        "paymentSource_cc"
      ],
      title: "Dummy 1 D2C - CC - Annual subscription (recurring) to Optus Sport",
      type: "subscription"
    },
    status: "active",
    title: "Dummy 1 D2C - CC - Annual subscription (recurring) to Optus Sport",
    type: "subscription"
  };
  const dupeSubscriptionCC2 = {
    accessToTags: [
      "cc-offer",
      "accessGroup_premium",
      "paymentSource_cc"
    ],
    expiresAt: 1599335304000,
    offerId: "DUMMYCCDUPE",
    period: "1m",
    price: 24.99,
    snapshotOffer: {
      accessGroups: [
        "Premium"
      ],
      active: true,
      id: "DUMMYCCDUPE",
      offerCode: "DUMMYCCDUPE title",
      period: "1m",
      price: 24.99,
      segmentTag: null,
      source: "cleeng",
      tags: [
        "cc-offer",
        "accessGroup_premium",
        "paymentSource_cc"
      ],
      title: "DUMMYCCDUPE title",
      type: "subscription"
    },
    status: "active",
    title: "DUMMYCCDUPE title",
    type: "subscription"
  };
  const dupeSubscriptionAppstore = {
    accessToTags: [
      "accessGroup_premium",
      "paymentSource_appstore"
    ],
    expiresAt: 1612335304000,
    offerId: "S933014502_AU",
    period: "1m",
    price: 24.99,
    snapshotOffer: {
      accessGroups: [
        "Premium"
      ],
      active: true,
      id: "S933014502_AU",
      offerCode: "appstore",
      period: "1m",
      price: 24.99,
      segmentTag: null,
      source: "cleeng",
      tags: [
        "accessGroup_premium",
        "paymentSource_appstore"
      ],
      title: "appstore",
      type: "subscription"
    },
    status: "active",
    title: "appstore",
    type: "subscription"
  };
  const postpaidSub = {
    accessToTags: [
      "accessGroup_premium",
      "paymentSource_postpaid",
      "segmentTag_optus_post_fixed"
    ],
    expiresAt: 1602335304000,
    offerId: "S118408955_AU",
    period: "1m",
    price: 0,
    snapshotOffer: {
      accessGroups: [
        "Premium"
      ],
      active: true,
      id: "S118408955_AU",
      offerCode: "optus_postpaid",
      period: "1m",
      price: 0,
      segmentTag: null,
      source: "cleeng",
      tags: [
        "accessGroup_premium",
        "paymentSource_postpaid",
        "segmentTag_optus_post_fixed"
      ],
      title: "Post Postpaid Subscription",
      type: "subscription"
    },
    status: "active",
    title: "Post Postpaid Subscription",
    type: "subscription"
  };
  transformed.push(postpaidSub);
  transformed.push(dupeSubscriptionAppstore);
  transformed.unshift(dupeSubscriptionCC);
  transformed.push(dupeSubscriptionCC2);
  */

  transformed = sortCustomerSubscriptions(transformed);

  // console.log(transformed);

  return transformed;
}

export function createOsOfferConfig(subscription, offersConfig) {
  // Create OS offer config if recurringSubscription.nextPaymentPrice and currentSubscription.price are not the same
  const osOfferConfig = [];
  const currentSubscription = getSubscription(subscription);
  if (!currentSubscription) return osOfferConfig;
  const {
    price: currentSubscriptionPrice,
    offerId: currentSubscriptionOfferId,
  } = currentSubscription;

  const recurringSubscription = subscription?.recurringSubscription?.find(
    (item) => item.offerId === currentSubscriptionOfferId,
  );

  const nextPaymentPrice = recurringSubscription?.nextPaymentPrice;

  if (nextPaymentPrice && nextPaymentPrice !== currentSubscriptionPrice) {
    Object.keys(offersConfig).forEach((offerId) => {
      const offerConfig = offersConfig[offerId];
      if (
        offerConfig.offers?.find(
          (offer) => offer.id === currentSubscriptionOfferId,
        )
      ) {
        osOfferConfig[currentSubscriptionOfferId] = {
          ...offerConfig,
          price: nextPaymentPrice,
        };
      }
    });
  }

  return osOfferConfig;
}

/**
 * getSubscription
 *
 * Retrieves either the current subscription or a duplicate
 * subscription which can be dictated by the activeOnly flag
 * i.e. By default only active dupes are returned
 *
 * @param {object} subscription The subscription Redux state
 * @param {boolean} current Whether to fetch current subscription or dupe
 * @param {boolean} activeOnly Whether to fetch only active dupe or not
 * @returns {object} current or duplicate subscription
 */
export function getSubscription(
  subscription,
  current = true,
  activeOnly = true,
) {
  return current
    ? getCurrentCustomerSubscription(subscription)
    : getDuplicatedCustomerSubscription(subscription, activeOnly);
}

export function getCustomerOffers(
  subscription,
  current = true,
  activeOnly = true,
) {
  const currentSubscription =
    getSubscription(subscription, current, activeOnly) || {};
  const { offers } = subscription;
  if (isEmpty(currentSubscription)) {
    return sortOffers(offers, false);
  }
  const { type, snapshotOffer } = currentSubscription;
  const withSnapshotOffer = uniqBy(
    [...offers, createDisabledOffer(snapshotOffer)],
    'id',
  ).filter(Boolean);
  const customerOffers = withSnapshotOffer.filter(
    (offer) => offer.type === type,
  );

  return sortOffers(customerOffers, false);
}

export function getExpiryDateMillis(
  subscription,
  current = true,
  activeOnly = true,
  gracePeriod = false,
) {
  const {
    expiresAt,
    originalExpiresAt,
    gracePeriodMs = 0,
  } = getSubscription(subscription, current, activeOnly) || {};

  // By default BE will return an expiresAt = Cleeng's expiresAt value + CLEENG_TOTAL_GRACE_PERIOD
  // Context: Active and Cancelled subscriptions can be valid until
  // Cleeng's expiresAt + Cleeng's grace period + Cleeng entitlement delay
  // However, terminated subscriptions are only ever valid until
  // Cleeng's expiresAt + Cleeng entitlement delay
  return expiresAt
    ? gracePeriod
      ? expiresAt
      : originalExpiresAt || expiresAt - gracePeriodMs
    : null;
}

export function getDisplayDate(
  subscription,
  current = true,
  activeOnly = true,
  gracePeriod = false,
  format = 'Do MMMM YYYY',
) {
  const netExpiresAt = getExpiryDateMillis(
    subscription,
    current,
    activeOnly,
    gracePeriod,
  );
  return netExpiresAt ? moment(netExpiresAt).format(format) : null;
}

export function getTitle(subscription, current = true, activeOnly = true) {
  const { title } = getSubscription(subscription, current, activeOnly) || {};
  return title;
}

export function getPriceNumber(price) {
  const priceNumber = parseFloat(price);
  return typeof priceNumber === 'number' ? priceNumber : 0;
}

export function getDisplayPrice(price, decimalPlaces = 2) {
  const priceNumber = parseFloat(price);
  return typeof priceNumber === 'number'
    ? priceNumber.toFixed(decimalPlaces)
    : '0.00';
}

export function getPrice(
  subscription,
  current = true,
  decimalPlaces = 2,
  activeOnly = true,
) {
  const { price = '0' } =
    getSubscription(subscription, current, activeOnly) || {};
  const priceNumber = getPriceNumber(price);

  return _.floor(priceNumber, decimalPlaces).toFixed(decimalPlaces);
}

export function getOfferPrice(offer, decimalPlaces = 2) {
  const { price = '0' } = offer || {};
  const priceNumber = getPriceNumber(price);

  return _.floor(priceNumber, decimalPlaces).toFixed(decimalPlaces);
}

export function getCurrency(subscription, current = true) {
  return DEFAULT_CURRENCY;
}

export function getOfferCurrency(offer) {
  return DEFAULT_CURRENCY;
}

export function getTax(
  price,
  subtotalPrice,
  subscription,
  current = true,
  decimalPlaces = 2,
  activeOnly = true,
) {
  const calculatedPrice =
    price || getPrice(subscription, current, decimalPlaces, activeOnly);
  const priceNumber = getPriceNumber(calculatedPrice || '0');
  const calculatedSubTotal =
    subtotalPrice ||
    getSubTotal(price, subscription, current, decimalPlaces, activeOnly);
  const subtotalNumber = getPriceNumber(calculatedSubTotal || '0');

  return (priceNumber - subtotalNumber).toFixed(decimalPlaces);
}

export function getOfferTax(offer, decimalPlaces = 2) {
  return (getOfferPrice(offer) - getOfferSubTotal(offer)).toFixed(
    decimalPlaces,
  );
}

export function getOfferSubTotal(offer, decimalPlaces = 2) {
  const { price = '0' } = offer || {};
  const priceNumber = getPriceNumber(price);

  return _.ceil(priceNumber / (1 + DEFAULT_TAX), decimalPlaces).toFixed(
    decimalPlaces,
  );
}

export function getSubTotal(
  price,
  subscription,
  current = true,
  decimalPlaces = 2,
  activeOnly = true,
) {
  const { price: calculatedPrice } = getSubscription(
    subscription,
    current,
    activeOnly,
  ) || { price: price || '0' };
  const priceNumber = getPriceNumber(calculatedPrice);

  return _.ceil(priceNumber / (1 + DEFAULT_TAX), decimalPlaces).toFixed(
    decimalPlaces,
  );
}

export function getPeriod(subscription, current = true, activeOnly = true) {
  const { period } = getSubscription(subscription, current, activeOnly) || {};
  return periodTextMapping[period] || {};
}

export function getType(subscription, current = true, activeOnly = true) {
  const targetSubscription = getSubscription(subscription, current, activeOnly);
  if (!targetSubscription) {
    return subscriptionTypes.none;
  }
  const { type } = targetSubscription;
  return offerTypes[type] || type;
}

export function getNewPrice(
  subscription,
  notificationConfig,
  isFuture = false,
  decimalPlaces = 2,
) {
  const nextBillingDateMillis = getExpiryDateMillis(subscription);
  const changeEffectDate = new Date(
    notificationConfig.priceChangeDate,
  ).getTime();
  if (nextBillingDateMillis >= changeEffectDate || isFuture) {
    const { period, offerId } = getSubscription(subscription);

    // Offer id's new price config should take priority over Offer period's new price config
    const newPrice =
      _.get(notificationConfig, `newPriceOffer[${offerId}]`) ||
      _.get(notificationConfig, `newPrice[${period}]`);
    if (newPrice) {
      const priceNumber = getPriceNumber(newPrice);
      return _.floor(priceNumber, decimalPlaces).toFixed(decimalPlaces);
    }
    return null;
  }
  return null;
}

export default function subscriptionReducer(state = initialState, action) {
  const { type, payload, apiActionContext } = action;
  switch (type) {
    case start(SUBSCRIPTION_GET_ALL): {
      return {
        ...state,
        offers: null,
        error: null,
      };
    }
    case success(SUBSCRIPTION_GET_ALL): {
      const { osOffers, osOffersExtra } = state;
      const offers = get(payload, `data.items`);
      const eligibleOffers = getEligibleOffers(offers, osOffers, osOffersExtra);
      const offersSorted = sortOffers([...offers, ...eligibleOffers], false);
      return {
        ...state,
        offers: offersSorted,
      };
    }
    case fail(SUBSCRIPTION_GET_ALL): {
      const { offers } = state;
      return {
        ...state,
        offers: offers || [],
        error: payload,
      };
    }
    case start(SUBSCRIPTION_GET_OS_OFFER): {
      return {
        ...state,
        osOffers: [],
        osOffersExtra: [],
        error: null,
      };
    }
    case success(SUBSCRIPTION_GET_OS_OFFER): {
      const tags = get(apiActionContext, `options.tags`);
      const systemConfig = get(apiActionContext, `options.systemConfig`);
      const osOffers = get(payload, `data.eligibleOffers.creditCard`, []);
      const getFeatureFlags = getFlags({ systemConfig });
      const osOffersConfig = getFeatureFlags('OFFERS_CONFIG');

      const osOffersExtra = [];
      osOffers.forEach((osOffer) => {
        const osOffersConfigKeys =
          (osOffersConfig && Object.keys(osOffersConfig)) || [];
        const osOfferConfigIndex = osOffersConfigKeys.findIndex(
          (offerId) => offerId === osOffer?.id,
        );

        if (osOfferConfigIndex !== -1) {
          const { ...osOffersConfigProps } =
            osOffersConfig[osOffersConfigKeys[osOfferConfigIndex]];
          osOffersExtra.push({
            id: osOffer?.id,
            ...osOffersConfigProps,
          });
        }
      });

      action.asyncDispatch(getOffers({ tags }));

      return {
        ...state,
        osOffers,
        osOffersExtra,
      };
    }
    case fail(SUBSCRIPTION_GET_OS_OFFER): {
      const { osOffers, osOffersExtra } = state;
      const tags = get(apiActionContext, `options.tags`);
      action.asyncDispatch(getOffers({ tags }));
      return {
        ...state,
        osOffers: osOffers || [],
        osOffersExtra: osOffersExtra || [],
        error: payload,
      };
    }
    case SUBSCRIPTION_SELECTION_UPDATED: {
      const { selections } = payload;
      return {
        ...state,
        selections,
      };
    }
    case start(CUSTOMER_SUBSCRIPTION_GET_ALL): {
      return {
        ...state,
        subscriptions: null,
        error: null,
        cancel: null,
      };
    }
    case success(CUSTOMER_SUBSCRIPTION_GET_ALL):
    case success(GET_USER_DETAILS): {
      const subscriptions = transformSubscriptionFromPayload(payload);
      return {
        ...state,
        subscriptions,
        error: null,
      };
    }
    case fail(CUSTOMER_SUBSCRIPTION_GET_ALL): {
      const { error } = payload;
      const code = getErrorCode(error);
      const errorData = !isNonCustomer(code) && { error };
      const { subscriptions } = state;
      return {
        ...state,
        ...errorData,
        subscriptions: subscriptions || [],
      };
    }
    case start(CUSTOMER_SUBSCRIPTION_UPDATE): {
      return {
        ...state,
        error: null,
      };
    }
    case success(CUSTOMER_SUBSCRIPTION_UPDATE): {
      action.asyncDispatch(getUserDetails({ forceRefreshCache: true }));
      action.asyncDispatch(
        getRecurringSubscriptionsAction(
          CUSTOMER_SUBSCRIPTION_GET_RECURRING,
          {},
        ),
      );
      return state;
    }
    case success(PAYMENT_INTENT): {
      action.asyncDispatch(getUserDetails({ forceRefreshCache: true }));
      action.asyncDispatch(
        getRecurringSubscriptionsAction(
          CUSTOMER_SUBSCRIPTION_GET_RECURRING,
          {},
        ),
      );
      return state;
    }
    case fail(CUSTOMER_SUBSCRIPTION_UPDATE): {
      return {
        ...state,
        error: payload,
      };
    }
    case start(CUSTOMER_SUBSCRIPTION_GET_RECURRING): {
      return {
        ...state,
        recurringSubscription: null,
      };
    }
    case success(CUSTOMER_SUBSCRIPTION_GET_RECURRING): {
      const recurringSubscription = get(payload, 'data', []);
      return {
        ...state,
        recurringSubscription,
      };
    }
    /*case fail(CUSTOMER_SUBSCRIPTION_GET_RECURRING): {
      const { error } = payload;
      const code = getErrorCode(error);
      const errorData = !isNonCustomer(code) && { error };
      const { subscriptions } = state;
      return {
        ...state,
        ...errorData,
        subscriptions: subscriptions || [],
      };
    }*/
    default:
      return state;
  }
}

// This assumes that accessToTags will have the following convention:
// `prefix-campaign-etc`
// Example: `paymentSource_voucher-jcarvajal-testing`
function retainPrefix(tags) {
  // TODO: undefined tags means that the subscriptions object from GetUserDetails has missing snapshotOffer.
  if (!tags) {
    return tags;
  }
  return tags.map((tag) => {
    const [prefix] = tag.split(`-`);
    return prefix;
  });
}

function sortTags(tags, desc = true) {
  const sortOrder = Object.values(subscriptionTypes);
  return retainPrefix(tags).sort(sortOrderComparator(sortOrder, desc));
}

/**
 * sortCustomerSubscriptions
 * Sort customer subscriptions based on priorities set in `subscriptionTypes`.
 * Sort order is from highest priority (end of the list) to lowest (subscriptionTypes.none).
 * This function needs to be called after retrieving the subscriptions via the API call.
 *
 * @param {array<Subscriptions>} subscriptions The array of subscriptions from the API call.
 * @returns
 */
function sortCustomerSubscriptions(subscriptions, desc = true) {
  const sortOrder = Object.values(subscriptionTypes);
  const comparator = sortOrderComparator(sortOrder, desc);
  return subscriptions.sort((s1, s2) => {
    const [tag1 = subscriptionTypes.none] = sortTags(
      get(s1, 'snapshotOffer.tags', []),
    );
    const [tag2 = subscriptionTypes.none] = sortTags(
      get(s2, 'snapshotOffer.tags', []),
    );
    return comparator(tag1, tag2, s1, s2);
  });
}

/**
 * getCurrentCustomerSubscription
 * Retrieves the current customer subscription.
 * For now, the subscriptions are sorted before-hand based on priorities.
 * So the FIRST one will be treated as the `current` subscription.
 * Refer to `sortCustomerSubscriptions` and `subscriptionTypes`
 *
 * @export
 * @param {SubscriptionState} subscription The subscription Redux state
 * @returns
 */
export function getCurrentCustomerSubscription(subscription) {
  // TODO: Handle multiple subscriptions with mixed cancelled and active.
  const { subscriptions } = subscription || {};
  if (isEmpty(subscriptions)) {
    return null;
  }
  const [activeSubscription = {}] = subscriptions;
  return activeSubscription;
}

/**
 * getDuplicatedCustomerSubscription
 * Retrieves the duplicated customer subscription.
 * For now, the subscriptions are sorted before-hand based on priorities.
 * So the SECOND one will be treated as the duplicated subscription.
 * Refer to `sortCustomerSubscriptions` and `subscriptionTypes`
 *
 * @export
 * @param {SubscriptionState} subscription The subscription Redux state
 * @param {boolean} activeOnly The subscription state equal to active
 * @returns
 */
export function getDuplicatedCustomerSubscription(
  subscription,
  activeOnly = true,
) {
  const { subscriptions } = subscription || {};
  if (isEmpty(subscriptions)) {
    return null;
  }

  let potentialDupeSubscriptions = _.slice(subscriptions, 1);

  // We should go through each subscription and return any dupe d2c such as creditcard, ios, or android
  // as we cannot always readily rely on the '2nd' subscription being the dupe
  let d2cDuplicatedSubscription;
  let duplicatedD2cSubscriptions = {};
  const supportedTags = Object.values(d2cSubscriptionTypes);
  _.each(
    potentialDupeSubscriptions,
    (dupeSubscription, dupeSubscriptionKey) => {
      const accessToTags = get(dupeSubscription, 'accessToTags');

      // We should skip any subscription type = 'pass' as those are not recurring
      // aka they have been paid for the period and we would only offer cancel/refund
      // via Cleeng dashboard under unique circumstances - not allowed by user themselves
      const subscriptionType = get(dupeSubscription, 'type');
      if (subscriptionType === offerTypes.pass) {
        return;
      }

      if (accessToTags) {
        const matchedTags = intersection(
          supportedTags,
          retainPrefix(accessToTags),
        );

        if (!isEmpty(matchedTags)) {
          matchedTags.reverse();
          const [dupeType] = matchedTags;
          const dupeKey = _.findKey(
            d2cSubscriptionTypes,
            (val) => val === dupeType,
          );

          if (dupeKey) {
            if (!duplicatedD2cSubscriptions[dupeKey]) {
              duplicatedD2cSubscriptions[dupeKey] = [];
            }

            duplicatedD2cSubscriptions[dupeKey].push(dupeSubscription);

            // Mark to remove from potentialDupeSubscriptions as it has been processed
            potentialDupeSubscriptions[dupeSubscriptionKey] = null;
          }
        }
      }
    },
  );

  // Remove processed d2c
  const newPotentialDupeSubscriptions = _.compact(potentialDupeSubscriptions);

  if (!isEmpty(duplicatedD2cSubscriptions)) {
    const d2cPriorityKeys = Object.keys(d2cSubscriptionTypes).reverse();

    _.each(d2cPriorityKeys, (d2cValue) => {
      const d2cDupeArr = get(duplicatedD2cSubscriptions, d2cValue, []);
      const d2cDupeItem = activeOnly
        ? _.find(
            d2cDupeArr,
            (sub) => get(sub, 'status') === subscriptionStates.active,
          )
        : _.slice(d2cDupeArr, 0, 1).shift();
      if (!isEmpty(d2cDupeItem)) {
        d2cDuplicatedSubscription = d2cDupeItem;
        return false;
      }
    });
  }

  if (d2cDuplicatedSubscription) {
    return d2cDuplicatedSubscription;
  }

  // Sort all remaining subscriptions by their expiresAt
  const sortedPotentialDupleSubscriptions = sortSubscriptionsByExpiresAt(
    newPotentialDupeSubscriptions,
  );

  // Default behaviour is to return the first subscription from potentialDupeSubscriptions
  // after processing/removing d2c subscriptions from it
  let duplicatedSubscription = activeOnly
    ? _.find(
        sortedPotentialDupleSubscriptions,
        (sub) =>
          get(sub, 'status') ===
          (subscriptionStates.active || subscriptionStates.pass),
      )
    : _.slice(sortedPotentialDupleSubscriptions, 0, 1).shift();
  return (
    duplicatedSubscription ||
    _.slice(sortedPotentialDupleSubscriptions, 0, 1).shift()
  );
}

export function hasCustomerSubscription(subscription) {
  const { subscriptions } = subscription || {};
  return !isEmpty(subscriptions);
}

export function hasMultipleCustomerSubscriptions(subscription) {
  const hasSubscription = hasCustomerSubscription(subscription);
  if (!hasSubscription) {
    return false;
  }
  const { subscriptions } = subscription;
  return subscriptions.length > 1;
}

export function isCustomerSubscriptionExpired(
  subscription,
  current = true,
  activeOnly = true,
) {
  // TODO: Use this line to simulate expiry...
  // return true;

  const targetSubscription = getSubscription(subscription, current, activeOnly);
  if (!targetSubscription) {
    // Expired or no active subscription means the same thing so return true
    return true;
  }
  const { expiresAt } = targetSubscription;
  return expiresAt < Date.now();
}

export function getNextPaymentPrice(subscription) {
  const recurringSubscription = getRecurringSubscription(subscription);
  return get(recurringSubscription, 'nextPaymentPrice', '');
}

export function getRecurringSubscription(subscription) {
  const recurringSubscription =
    get(subscription, 'recurringSubscription', []) || [];
  return !isEmpty(recurringSubscription) ? first(recurringSubscription) : {};
}

export function isCustomerSubscriptionCancelled(
  subscription,
  current = true,
  activeOnly = true,
) {
  // TODO: Use this line to simulate cancelled...
  // return true;

  const targetSubscription = getSubscription(subscription, current, activeOnly);
  if (!targetSubscription) {
    return null;
  }
  const { status } = targetSubscription;
  return status === subscriptionStates.cancelled;
}

export function isCustomerSubscriptionActive(
  subscription,
  current = true,
  activeOnly = true,
) {
  // TODO: Use this line to simulate active...
  // return true;

  const targetSubscription = getSubscription(subscription, current, activeOnly);
  if (!targetSubscription) {
    return null;
  }
  const { status, type } = targetSubscription;
  return (
    status === subscriptionStates.active ||
    (type === offerTypes.pass && status === subscriptionStates.pass)
  );
}

export function getSubscriptionTypes(
  subscription,
  current = true,
  activeOnly = true,
) {
  const targetSubscription = getSubscription(subscription, current, activeOnly);
  if (!targetSubscription) {
    return [subscriptionTypes.none];
  }
  const { accessToTags } = targetSubscription;
  const supportedTags = Object.values(subscriptionTypes);
  const matchedTags = intersection(supportedTags, retainPrefix(accessToTags));
  return matchedTags.reverse();
}

export function getSubscriptionType(
  subscription,
  current = true,
  activeOnly = true,
) {
  // TODO: Use this line to simulate subscription types
  // return subscriptionTypes.postpaid;

  const [type] = getSubscriptionTypes(subscription, current, activeOnly);
  return type;
}

export function getCustomerSubscriptionState(
  subscription,
  current = true,
  activeOnly = true,
) {
  // TODO: Use this line to simulate state...
  // return subscriptionStates.none;

  const { subscriptions } = subscription || {};
  if (isEmpty(subscriptions)) {
    return subscriptionStates.none;
  }
  if (isCustomerSubscriptionExpired(subscription, current, activeOnly)) {
    return subscriptionStates.expired;
  }
  const targetSubscription = getSubscription(subscription, current, activeOnly);
  if (!targetSubscription) {
    return null;
  }
  const validStates = [
    subscriptionStates.active,
    subscriptionStates.cancelled,
    subscriptionStates.pass,
  ];
  const { status } = targetSubscription;
  if (validStates.indexOf(status) === -1) {
    return subscriptionStates.invalid;
  }
  return status;
}

export function updateSelections(selections) {
  return {
    type: SUBSCRIPTION_SELECTION_UPDATED,
    payload: {
      selections,
    },
  };
}

export const filterOffersByTags = ({ subscription = {}, tags = [] }) => {
  const { offers } = subscription;
  const filteredOffers = offers?.filter((offer) => {
    // for each offer go inside and the find tags.
    const { tags: innerTags = [] } = offer;
    const foundMatch = innerTags.some((innerFilter) => {
      return tags.includes(innerFilter);
    });
    return !foundMatch;
  });
  return filteredOffers;
};

export function getOffers({ tags } = {}) {
  const params = tags && {
    queryStringParameters: {
      tags,
    },
  };
  return apiAction(SUBSCRIPTION_GET_ALL, `fe-api-getOffers`, params);
}
export function getOsOffers({ tags }) {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    dispatch(
      apiAction(SUBSCRIPTION_GET_OS_OFFER, 'fe-api-getOsOffers', {
        method: 'post',
        path: `${currentUserId}/offers`,
        tags,
        systemConfig: state.systemConfig,
      }),
    );
  };
}

function withForceCacheRefresh({ systemConfig }) {
  const getFeatureFlags = getFlags({ systemConfig });

  return (options) => {
    const { forceRefreshCache, queryStringParameters, ...rest } = options;
    // Workaround: Cleeng has a 1-3 second delay from a create or update subscription.
    const ignoreCache = forceRefreshCache && { ignoreCache: `true` };
    const workaroundDelay = getFeatureFlags(
      `FEATURE_PAYMENT_WORKAROUND_SUBSCRIPTION_STATUS_DELAY`,
    );
    const waitBeforeAction = forceRefreshCache && {
      waitBeforeAction: workaroundDelay,
    };
    return {
      queryStringParameters: {
        ...ignoreCache,
        ...queryStringParameters,
      },
      ...waitBeforeAction,
      ...rest,
    };
  };
}

/**
 * getUserDetailsAction
 * Triggers the GetUserDetails API call and attaching an action type to this trigger.
 * This enables dispatching different action types for the same GetUserDetails API call.
 *
 * @export
 * @param {*} action
 * @param {*} options
 * @returns
 */
export function getUserDetailsAction(action, options) {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    const getOptions = withForceCacheRefresh(state);
    dispatch(
      apiAction(
        action,
        `fe-api-userGetUserDetails`,
        getOptions({
          ...options,
          path: `/${currentUserId}`,
        }),
      ),
    );
  };
}

export function getUserDetails(options = {}) {
  return (dispatch) => {
    dispatch(getUserDetailsAction(GET_USER_DETAILS, options));
  };
}

export function getRecurringSubscriptionsAction(action, options) {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    const getOptions = withForceCacheRefresh(state);
    dispatch(
      apiAction(
        action,
        `fe-api-recurringSubscriptions`,
        getOptions({
          ...options,
          path: `/${currentUserId}/recurringSubscriptions`,
        }),
      ),
    );
  };
}

export function getCustomerSubscriptions(options = {}) {
  return (dispatch) => {
    dispatch(resetLoader(CUSTOMER_SUBSCRIPTION_UPDATE));
    dispatch(getUserDetailsAction(CUSTOMER_SUBSCRIPTION_GET_ALL, options));
    dispatch(
      getRecurringSubscriptionsAction(
        CUSTOMER_SUBSCRIPTION_GET_RECURRING,
        options,
      ),
    );
  };
}

export function cancelCustomerSubscription(offerId) {
  return apiAction(
    CUSTOMER_SUBSCRIPTION_UPDATE,
    `fe-api-subscriptions-update`,
    {
      method: `post`,
      body: {
        offerId,
        status: `cancelled`,
      },
    },
  );
}

export function activateCustomerSubscription(offerId) {
  return apiAction(
    CUSTOMER_SUBSCRIPTION_UPDATE,
    `fe-api-subscriptions-update`,
    {
      method: `post`,
      body: {
        offerId,
        status: `active`,
      },
    },
  );
}

function hasOsOffer(offers, osOfferId) {
  const result = offers.filter((offer) => {
    return offer.id === osOfferId;
  }).length;
  return !!result;
}

function createEligibleOffer(offer, osOffer, osOffersExtra) {
  const newOfferData = cloneDeep(offer);
  const osOfferTitle = osOffersExtra?.find(
    (item) => item.id === osOffer.id,
  )?.title;
  newOfferData.id += `::${osOffer.id}`;
  newOfferData.title = osOfferTitle;
  newOfferData.offerCode = osOfferTitle;
  newOfferData.price = osOffer.discount.price;
  newOfferData.osOfferId = osOffer.id;
  newOfferData.osDiscount = osOffer.discount;
  return newOfferData;
}

function getEligibleOffers(offers, osOffers, osOffersExtra) {
  const eligibleOffers = [];

  const hasOsOfferData = (osOffer) =>
    osOffersExtra?.find((osOfferConfig) => osOffer.id === osOfferConfig.id);
  // hasOsOfferData : to avoid adding offers that are not configured in fe-config

  if (offers?.length && osOffers?.length) {
    offers.forEach((offer) => {
      const foundOsOffer = osOffers?.filter((osOffer) =>
        osOffer.store.offerIds?.find((offerId) => offerId === offer.id),
      ); // find OS offers that has offer ID in store

      foundOsOffer.forEach((osOffer) => {
        !hasOsOffer(eligibleOffers, `${offer.id}::${osOffer.id}`) && // check if the same id exist
          hasOsOfferData(osOffer) && // check if this os offer is in fe-config
          eligibleOffers.push(
            createEligibleOffer(offer, osOffer, osOffersExtra),
          );
      });
    });
  }
  return eligibleOffers;
}

// search OS Offer config by cloned ID eg) S123234345_AU::web-six-month-creadit-card-1
export function getOsOfferConfigByClonedOfferId(offerId, subscription) {
  const { offers, osOffersExtra } = subscription;
  const offer = offers?.find((offer) => offer.id === offerId);
  const osOfferId = offer?.osOfferId;
  if (osOfferId && osOffersExtra) {
    return osOffersExtra.find(
      (osOfferConfig) => osOfferConfig.id === osOfferId,
    );
  }
  return false;
}
