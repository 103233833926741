export const SubhubKey = 'SUBHUB';

export const offersMapping = (offers) => {
  return offers?.map((offer) => {
    const {
      frontendConfig: {
        price: displayPrice,
        promoText,
        title: planTitle,
        subscriptionSubtext,
        badgeText,
        beforePrice,
        tncShortForm,
        tncLongForm,
        subscribeViaSubhubLink,
      } = {},
      planKey,
    } = offer;
    return {
      ...offer,
      price: offer?.discount?.price || offer?.normalPrice,
      showSubscription: true,
      displayPrice,
      promoText,
      planTitle,
      subscriptionSubtext,
      badgeText,
      beforePrice,
      tncShortForm,
      tncLongForm,
      canSubscribeViaSubhub: planKey === SubhubKey,
      subscribeViaSubhubLink:
        subscribeViaSubhubLink ||
        'https://subhub.optus.com.au/streaming-services/optussport',
    };
  });
};
