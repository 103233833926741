const { filter } = require('lodash');
const {
  getValidatedJsonFromString,
  stripHtmlTags,
  ALLOWED_HTML_TAGS,
} = require('parser/utils');

export function getSubscriptionPlansFromConfig(offers) {
  const offersJson = getValidatedJsonFromString(offers, []);
  const subscriptionPlans =
    filter(offersJson, {
      showSubscription: true,
    }) || [];
  return subscriptionPlans;
}

export function getStrInHTML(str) {
  return {
    __html: stripHtmlTags(str, ALLOWED_HTML_TAGS.DEFAULT),
  };
}

export const getPlatformTypeByTag = ({ tag, ctvConfig }) => {
  const ctv = find(ctvConfig, (item) => includes(item.tags, tag));
  return ctv ? ctv.platformTypeId : 'web';
};
